<template>
  <div class="contact">
    <h1>Contact</h1>
    <div class="contact-wrapper">
      <img src="../assets/contact.jpg">
      <div class="contact-text">
         <div class="contact-container">
          <form name="query_form">
            <div v-if="displayThanks">
              <p>Thank you for contacting us, we will be in touch soon</p>
            </div>
              <p>At present, the locations of only about 180 of Folke’s five hundred or more paintings and other works are known. Any information about them would be gratefully received by his family, who would like to record them and are always looking to add to their collection of his works should any private vendors wish to sell.</p>
              <p>Folke’s biography, written by his nephew Michael Heybrook, is available for sale with an accompanying illustrated book. Please contact us for a copy.</p>
            <div class="form-item">
              <label for="name">Name :</label>
              <input type="text" v-model="name" id="name" name="name" placeholder="">
            </div>
            <div class="form-item">
              <label for="email">Email :</label>
              <input type="text" v-model="email" id="email" name="email" placeholder="">
            </div>
            <div class="form-item">
              <textarea id="subject"  v-model="queryBody" name="subject" placeholder="Type your message here..." style="height:75px"></textarea>
            </div>
            <div class="submit-button">
              <input type="submit" class="submit" value="Submit" v-on:click="sendQuery(name, queryBody); return false">
            </div>
            <div class="copyright">
              <p>All images © <a href="/">www.FolkeHeybroek.com</a>  All rights reserved.</p>
            </div>
          </form>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>

  export default {
  data: () => ({
      name: '',
      email:'',
      queryBody:'',
      displayThanks: false,
  }),
  mounted() {
  },
  methods:{
    sendQuery(name, queryBody) {
      var mailto = "mailto:laura@lauraheybrook.com"
             + "?subject=" + encodeURIComponent("Website Query - " + name)
             + "&body=" + encodeURIComponent(queryBody)
      ;
      
      window.location.href = mailto;
    }
  }
}
</script>

<style lang="scss" scoped>
.contact {
  margin-top: 140px;
  height: 100vh;
  margin-left: 75px;
  margin-right: 75px;
  padding-top: 10px;
}
.contact-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 250px;
}
.contact-text{
  width: 40%;
}
.contact-text p {
  margin-top: 0;
  line-height: 1.5;
}
.contact-wrapper img{
  width: 55%;
  object-fit: cover;
  // height: auto;
}

h1 {
  color: #801818;
  font-size: 30px;
  font-weight: 700;
}
p {
  font-size: 18px;
  font-weight: 500;
}
h1, p{
  text-align: left;
}
input[type=text], select, textarea {
  font-size: 28px;
  width: 99%; 
  border: none;
  border-radius: 0px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  padding-left: 0;
  resize: vertical;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  border-bottom: 2px black solid;
  border-radius: 0px;
  &::placeholder {
    color: black; 
    opacity: 1;
  }
}

input[type=submit] {
  background: transparent;
  font-weight: bold;
  color: #801818;
  font-size: 24px;
  border: none;
  cursor: pointer;
  // background-image: url(../assets/arrow.svg);
  // background-repeat: no-repeat;
  // background-size: contain;
  height: max-content;
  width: max-content;
}

input[type=submit]:hover {
  text-decoration: underline;
}
.contact-container {
  color: black;
  border-radius: 5px;
  font-size: 28px;
  height: 100%;
}
.contact-container {
  form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.form-item {
  display: flex;
}
.form-item label{
  font-weight: bold;
  margin-top: 5px;
  display: flex;
  width: fit-content;
}
.form-item input{
  width: 80%;
  display: flex;
}
.submit-button {
  width: 100%;
  display: flex;
  justify-content: left;
}
.copyright {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  p {
    margin: 0px;
  }
}

@media (max-width: 1500px) {
  .form-item input{
    width: 75%;
  }
  .form-item label{
    font-size: 24px;
  }
  input[type=text], select, textarea {
    font-size: 22px;
  }

  .contact-wrapper img{
    // height: 20%;
  }
}
@media (max-width: 1200px) {
  .form-item input{
    width: 70%;
  }
  .form-item {
    justify-content: space-between;
  }
  input[type=text], select, textarea {
    font-size: 22px;
  }
  .contact-wrapper img{
    width: 50%;
    // height: 10%;
  }

  .contact-text{
    width: 45%;
  } 
}
@media (max-width: 850px) {
  .contact-wrapper img {
    width: 100%;
    height: 100%;
  }
  .contact-text {
    width: 100%;
  }
}
@media (max-width: 550px) {
  .contact {
    margin-top: 100px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .contact-wrapper {
    margin-bottom: 350px;
  }
  .form-item label{
    font-size: 22px;
    width: fit-content;
  }
  input[type=text], select, textarea {
    font-size: 18px;
  }
  input[type=submit] {
    font-size: 22px;
  }
  .form-item input{
    width: 70%;
  }
  p {
    font-size: 16px;
  }
}
</style>